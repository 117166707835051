<template>
    <div>
        <!--begin::customer-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{ $t('cost_center.add_sales_invoice_to_cost_center')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
                    <router-link to="/sales/sales_invoices" class="btn btn-light font-weight-bolder">
                        <v-icon>mdi-chevron-left</v-icon>
                        {{ $t('back') }}
                    </router-link
                    >
                    <!--end::Button-->
                </div>
            </div>
            <div class="card-body">

                <div class="card-body p-0">
                    <div class="form-group row">
                        <div class="col-md-12 mt-5">
                            <table class="table text-center border-secondary">
                                <thead>
                                <tr>
                                    <th>{{$t('cost_center.account_id')}}</th>
                                    <th>{{$t('cost_center.account_name')}}</th>
                                    <th>{{$t('cost_center.debit')}}</th>
                                    <th>{{$t('cost_center.credit')}}</th>
                                </tr>

                                </thead>
                                <tbody>
                                <tr>
                                    <td>#{{account_data.account_id}}</td>
                                    <td>{{account_data.account_name}}</td>
                                    <td>{{account_data.debit}}</td>
                                    <td>{{account_data.credit}}</td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-12">
                            <hr>
                        </div>
                        <div class="col-md-12">
                            <div class="d-flex justify-content-end mt-2 p-2 bg-FFB803">
                                <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{$t('add_more')}}</button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-row-bordered">
                                <thead>
                                <tr>
                                    <th >{{$t('cost_center.cost_center')}}</th>
                                    <th width="20%">{{$t('cost_center.percentage')}}</th>
                                    <th width="20%">{{$t('cost_center.amount')}}</th>
                                    <th></th>
                                </tr>

                                </thead>
                                <tbody>
                                <tr v-for="(row, index) in cost_center" :key="index">
                                    <td>
                                        <treeselect
                                                :options="cost_center_list"
                                                :load-options="loadOptions"
                                                :multiple="false"
                                                :value="row.cost_center_id"
                                                @input="updateValue(index, $event)"
                                                :searchable="true"
                                                :show-count="true"
                                                :no-children-text="$t('No_sub_options')"
                                                :no-options-text="$t('No_options_available')"
                                                :no-results-text="$t('No_results_found')"
                                                :placeholder="$t('Select')">
                                        </treeselect>
                                    </td>
                                    <td><input v-model="row.percentage" @input="addPercentage(index)" type="number" class="form-control"></td>
                                    <td><input v-model="row.amount" type="number" readonly class="form-control"></td>
                                    <td>
                                        <v-icon color="danger" small v-if="cost_center.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>{{$t('cost_center.total')}}</th>
                                    <th><span>{{total_percentage}}</span></th>
                                    <th><span>{{total_amount}}</span></th>
                                    <th></th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-footer pl-0 pr-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end::customer-->
    </div>
</template>
<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "form-cost-center",
        data() {
            return {
                mainRoute: 'sales/cost-centers',
                mainRouteDependency: 'base/dependency',
                mainRouteInvoice: 'sales/sales_invoices',

                account_data: {
                    account_id: null,
                    account_name: null,
                    debit: null,
                    credit: null,
                },
                idEditing: this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
                isEditing: false,
                cost_center_list: [],

                cost_center: [],
                cost_center_form: {
                    type_type: this.$route.query.type_type ? this.$route.query.type_type : "SalesInvoice",
                    type_id: this.$route.params.sales_invoice_id,
                    account_id: null,

                    cost_center_id: null,
                    amount: null,
                    percentage: null
                },
                total_amount: 0,
                total_percentage: 0,
                validation: null,
            };
        },
        watch: {
            cost_center: {
                handler(val) {
                    if (val.length > 0) {
                        this.total_amount = this.$_.sumBy(this.cost_center, (row) => {
                            return row.amount ? parseFloat(row.amount) : 0;
                        });
                        this.total_amount = this.total_amount.toFixed(2);

                        this.total_percentage = this.$_.sumBy(this.cost_center, (row) => {
                            return row.percentage ? parseFloat(row.percentage) : 0;
                        });
                        this.total_percentage = this.total_percentage.toFixed(2);

                    }

                },
                deep: true,
            }
        },
        methods: {
            save() {
                // if (this.isEditing) {
                //     this.update();
                // } else {
                //     this.create();
                // }
                this.create();
            },

            create() {
                ApiService.post(`${this.mainRoute}`, {
                    cost_center: this.cost_center
                }).then((response) => {
                        this.validation = null;
                        this.$successAlert(response.data.message);
                        this.$router.push({name: 'sales_invoices.index'});
                    })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },


            update() {
                ApiService.put(`${this.mainRoute}/${this.idEditing}`, {
                    cost_center: this.cost_center
                }).then((response) => {
                    this.validation = null;
                    this.$router.push({name: 'sales_invoices.index'});
                    this.$successAlert(response.data.message);
                })
                    .catch((error) => {
                        this.$errorAlert(error);
                        this.validation = error.response ? error.response.data.errors : null;
                    });
            },

            async getData() {
                await ApiService.get(`${this.mainRoute}/${this.idEditing}`,{params:{type_type: 'SalesInvoice', type_id: this.idEditing}}).then((response) => {
                    this.isEditing = true;
                    this.cost_center = response.data.data;

                    if (response.data.data && response.data.data.length <= 0) {
                        this.addItemRowToList();
                    }
                });
            },


            getCostCenterList() {
                ApiService.get(this.mainRouteDependency + "/cost_centers").then((response) => {
                    this.cost_center_list = response.data.data;
                });
            },
            async getAccountingRoute() {
                await ApiService.get(this.mainRouteDependency + "/accounts_routing",{params:{key: 'sales'}}).then((response) => {
                    this.account_data.account_id = response.data.data.account_id;
                    this.account_data.account_name = response.data.data.account_name;
                    this.account_data.debit = response.data.data.debit;
                    // this.account_data.credit = response.data.data.credit;
                    this.cost_center_form.account_id = response.data.data.account_id;
                });
            },
            async getInvoice() {
                await ApiService.get(this.mainRouteInvoice + "/"+this.idEditing).then((response) => {
                    this.account_data.credit = response.data.data.invoice_total;
                });
            },

            addItemRowToList() {
                this.cost_center.unshift(this.cost_center_form);
                this.cost_center_form = {
                    type_type: "SalesInvoice",
                    type_id: this.$route.params.sales_invoice_id,
                    account_id: this.cost_center_form.account_id,
                    cost_center_id: null,
                    amount: null,
                    percentage: null
                };
            },
            removeItemRowFromList(index) {
                this.cost_center.splice(index, 1);
            },

            loadOptions() {
            },
            updateValue(index, value) {
                this.cost_center[index].cost_center_id = value
            },
            addPercentage(index){
                this.cost_center[index].amount = (this.cost_center[index].percentage ? (parseFloat(this.cost_center[index].percentage)/100) : 0) * (this.account_data.credit ? parseFloat(this.account_data.credit) : 0);
                this.cost_center[index].amount = this.cost_center[index].amount.toFixed(2);
            }
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.add_sales_invoice_to_cost_center")}]);
            this.getCostCenterList();
            this.getInvoice();

            let that = this;
            let promise1 = this.getAccountingRoute();

            Promise.all([promise1]).then(()=>{
                let promise = that.getData();
                Promise.all([promise]).then(()=>{
                    if (that.cost_center.length <= 0) {
                        that.addItemRowToList();
                    }
                });
            });

        },
    };
</script>